import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  ListGroupItem,
  Form,
} from "react-bootstrap";
import { toast } from "react-toastify";
import CheckoutSteps from "../components/CheckoutSteps";
import Message from "../components/Message";
import Loader from "../components/Loader";
import Meta from "../components/Meta";
import {
  useCreateOrderMutation,
  useApplyCouponMutation,
} from "../slices/ordersApiSlice";
import { clearCartItems } from "../slices/cartSlice";

const PlaceOrderScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentPath = useLocation().pathname;

  const cart = useSelector((state) => state.cart);

  const [createOrder, { isLoading, error }] = useCreateOrderMutation();

  useEffect(() => {
    if (!cart.shippingAddress.address) {
      navigate("/shipping");
    } else if (!cart.paymentMethod) {
      navigate("/payment");
    }
  }, [cart.paymentMethod, cart.shippingAddress.address, navigate]);

  const [couponCode, setCouponCode] = useState("");
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [applyCoupon, { isLoading: applyingCoupon, error: applyCouponError }] =
    useApplyCouponMutation();
  const [couponDetails, setCouponDetails] = useState(null);

  // Check if any cart item has the brand "NAPCO"
  const hasNapcoBrand = cart.cartItems.some((item) => item.brand === "NAPCO");

  const applyCouponHandler = async () => {
    if (!couponCode) {
      toast.error("Please enter a coupon code");
      return;
    }

    try {
      const response = await applyCoupon({
        couponCode,
        cartItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
      }).unwrap();

      // Dispatch action to update cart based on the response
      dispatch({
        type: "cart/applyCoupon",
        payload: {
          ...response,
          couponId: response.couponId, // Update the state with the couponId
        },
      });

      setIsCouponApplied(true);
      setCouponDetails({
        code: couponCode,
        discount: response.discountPercentage,
      });
      toast.success(`Coupon "${couponCode}" applied successfully!`);
    } catch (error) {
      console.error("Coupon application error:", error);
      toast.error(applyCouponError?.data?.message || "Failed to apply coupon");
    }
  };

  const guestEmail = localStorage.getItem("guestEmail");

  console.log("Cart Items: ", cart.cartItems);

  const placeOrderHandler = async () => {
    try {
      const orderData = {
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        handlingFee: cart.handlingFee,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
        ...(isCouponApplied && { couponId: cart.couponId }),
      };

      // If there's a guest email, include it in the order data
      if (guestEmail) {
        orderData.email = guestEmail;
      }

      const res = await createOrder(orderData).unwrap();
      dispatch(clearCartItems());
      navigate(`/order/${res._id}`);
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <Meta title="Confirm Order | Lugo Parts & Restoration" />
      <CheckoutSteps step1 step2 step3 step4 />
      <h2>Order Confirmation: Proceed to Payment</h2>
      <p>
        Please review your order details and proceed to payment on the next
        page.
      </p>
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>Shipping</h2>
              <p>
                <strong>Name: </strong>
                {cart.shippingAddress.name}
              </p>
              <p>
                <strong>Phone: </strong> {cart.shippingAddress.phone}
              </p>
              <p>
                <strong>Address:</strong>
                <br />
                {cart.shippingAddress.address}
                {cart.shippingAddress.apartment && (
                  <>
                    {" "}
                    <br /> {cart.shippingAddress.apartment}
                  </>
                )}
                <br />
                {cart.shippingAddress.city}
                <br />
                {cart.shippingAddress.state}, {cart.shippingAddress.postalCode}
                <br />
                {cart.shippingAddress.country}
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Payment Method</h2>
              <strong>Method: </strong>
              {cart.paymentMethod}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Order Items</h2>
              {cart.cartItems.length === 0 ? (
                <Message>Your cart is empty</Message>
              ) : (
                <ListGroup variant="flush">
                  {cart.cartItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link
                            to={`/product/${item._id}?goBackPath=${currentPath}`}
                          >
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={4}>
                          {item.qty} x ${item.price} = ${item.qty * item.price}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card className="mt-0.5" style={{ border: "1px solid #636466" }}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>Order Summary</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Items(s) Subtotal:</Col>
                  <Col>${cart.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>
                    Estimated Shipping<sup>*</sup>:
                  </Col>
                  <Col>${cart.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>
                    Estimated Handling Fee<sup>*</sup>:
                  </Col>
                  <Col>${cart.handlingFee}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Estimated Tax:</Col>
                  <Col>${cart.taxPrice}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Total:</Col>
                  <Col>${cart.totalPrice}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col md={8}>
                    <Form.Control
                      type="text"
                      placeholder="Coupon Code"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                      disabled={isCouponApplied || hasNapcoBrand} // Disable if coupon is applied or if any item is from NAPCO
                    />
                  </Col>
                  <Col>
                    <Button
                      onClick={applyCouponHandler}
                      disabled={
                        isCouponApplied || applyingCoupon || hasNapcoBrand
                      } // Disable button similarly
                    >
                      Apply Coupon
                    </Button>
                  </Col>
                </Row>
                {hasNapcoBrand && (
                  <Row className="mt-2">
                    <Col>
                      <Message variant="info">
                        Coupons cannot be applied to NAPCO products.
                      </Message>
                    </Col>
                  </Row>
                )}
                {isCouponApplied && couponDetails && (
                  <Message variant="success">
                    Coupon "{couponDetails.code}" was applied.{" "}
                    {couponDetails.discount}% off items!
                  </Message>
                )}
                {applyCouponError && (
                  <Message variant="danger">
                    {applyCouponError.data?.message || "Error applying coupon"}
                  </Message>
                )}
              </ListGroup.Item>

              <Row>
                <Col xs={12}>
                  <small className="text-muted">
                    <sup>*</sup>Shipping cost is estimated; overages or
                    shortages will be reconciled.
                  </small>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={12}>
                  <small className="text-muted">
                    <sup>*</sup>Our handling fee covers the costs of order
                    processing and fulfillment, ensuring accurate and timely
                    delivery of your purchase. This modest charge supports
                    operational expenses and fair compensation for our dedicated
                    team.
                  </small>
                </Col>
              </Row>

              <ListGroupItem>
                {error && (
                  <Message variant="danger">{error.data.message}</Message>
                )}
              </ListGroupItem>

              <ListGroup.Item>
                <Button
                  type="button"
                  className="btn-block"
                  disabled={cart.cartItems.length === 0}
                  onClick={placeOrderHandler}
                >
                  Proceed to Payment
                </Button>
                {isLoading && <Loader />}
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default PlaceOrderScreen;
