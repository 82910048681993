import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Badge,
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Alert,
} from "react-bootstrap";
import { FaShoppingCart, FaUser } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useLogoutMutation } from "../slices/usersApiSlice";
import { logout } from "../slices/authSlice";
import { resetCart } from "../slices/cartSlice";
import SearchBox from "./SearchBox";
import logo from "../assets/logo2.png";
import { useResendVerificationEmailMutation } from "../slices/usersApiSlice";
import { toast } from "react-toastify";
import "../assets/styles/Header.css";

const Header = () => {
  const { cartItems } = useSelector((state) => state.cart);
  const { userInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [resendVerificationEmail, { isLoading: isResending }] =
    useResendVerificationEmailMutation();

  const handleResendEmail = async () => {
    try {
      await resendVerificationEmail().unwrap();
      toast.success("Verification email resent. Please check your inbox.");
    } catch (error) {
      toast.error("Failed to resend verification email. Please try again.");
    }
  };

  const [showAlert, setShowAlert] = useState(true);

  const [logoutApiCall] = useLogoutMutation();

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      // NOTE: here we need to reset cart state for when a user logs out so the next
      // user doesn't inherit the previous users cart and shipping
      dispatch(resetCart());
      navigate("/login");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <header>
        <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
          <Container style={{ maxWidth: "1740px" }}>
            <LinkContainer to="/">
              <Navbar.Brand>
                <img src={logo} alt="Lugo Parts" className="logo" />
              </Navbar.Brand>
            </LinkContainer>

            <Navbar.Toggle area-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              {/* Testing Nav Bar Changes. If break, move back above navbar.toggle*/}
              <Nav className="navbar-nav desktop-nav-tabs">
                <NavDropdown title="Services" id="services-dropdown">
                  <LinkContainer to="/restoration">
                    <NavDropdown.Item>Restoration</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/parts">
                    <NavDropdown.Item>Parts</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
                <LinkContainer to="/store-front">
                  <Nav.Link>Store</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/gallery">
                  <Nav.Link>Gallery</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/about">
                  <Nav.Link>About</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/career">
                  <Nav.Link>Careers</Nav.Link>
                </LinkContainer>
                <NavDropdown title="FAQ" id="faq-dropdown">
                  <LinkContainer to="/faq">
                    <NavDropdown.Item>General Questions</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/privacy">
                    <NavDropdown.Item>Privacy Policy</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/return-policy">
                    <NavDropdown.Item>Return Policy</NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to="/contactus">
                    <NavDropdown.Item>Contact Us</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              </Nav>
              <Nav className="ms-auto">
                <SearchBox />

                <LinkContainer to="/cart">
                  <Nav.Link>
                    <FaShoppingCart /> Cart
                    {cartItems.length > 0 && (
                      <Badge
                        pill
                        style={{
                          backgroundColor: "#e50914",
                          marginLeft: "5px",
                        }}
                      >
                        {cartItems.reduce((a, c) => a + c.qty, 0)}
                      </Badge>
                    )}
                  </Nav.Link>
                </LinkContainer>
                {userInfo ? (
                  <NavDropdown title={userInfo.name} id="username">
                    <LinkContainer to="/profile">
                      <NavDropdown.Item>Profile</NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Item onClick={logoutHandler}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  <LinkContainer to="/login">
                    <Nav.Link href="/login">
                      <FaUser /> Sign In
                    </Nav.Link>
                  </LinkContainer>
                )}
                {userInfo && userInfo.isAdmin && (
                  <NavDropdown title="Admin Panel" id="adminmenu">
                    <LinkContainer to="admin/productlist">
                      <NavDropdown.Item>Products</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="admin/userlist">
                      <NavDropdown.Item>Users</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="admin/orderlist">
                      <NavDropdown.Item>Orders</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/admin/tax">
                      <NavDropdown.Item>Tax Panel</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/admin/couponlist">
                      <NavDropdown.Item>Coupon Panel</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/admin/restorationlist">
                      <NavDropdown.Item>
                        Restoration Submissions
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/admin/partlist">
                      <NavDropdown.Item>Part Inquiries</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/admin/careerlist">
                      <NavDropdown.Item>Career Submissions</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
      {userInfo && !userInfo.active && showAlert && (
        <Alert
          variant="warning"
          className="text-center"
          dismissible
          onClose={() => setShowAlert(false)}
        >
          Your email is not verified. Please check your inbox for the
          verification link.{" "}
          <span
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: "#007bff",
            }}
            onClick={handleResendEmail}
            disabled={isResending}
          >
            Resend Email Verification
          </span>
        </Alert>
      )}
    </>
  );
};

export default Header;
